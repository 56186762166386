import React from 'react'

const Services = () => {

	return(

			<div className="container">

			<h1 className="text-center" style={{paddingTop: "30%"}}>
				Message sent Succesfully.
			</h1>

			</div>
		)

}

export default Services;