import React from 'react'

const Testimonial = () => {

	return(

			<div className="container">

			<h1 className="text-center" style={{paddingTop: "30%"}}>
				Testimonial
			</h1>

			</div>
		)

}

export default Testimonial;