import React from 'react'

const About = () => {

	return(

			<div className="container">

			<h1 className="text-center" style={{paddingTop: "30%"}}>
				About
			</h1>

			</div>
		)

}

export default About;
